<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <h1>kdjfksdjfksjdf {{unelgeeRefPath}}</h1>
  </v-container>
</template>

<script>
export default {
  components: {   },
  data: () => ({}),
  computed: {},
  created() {},
  methods: {},
    props: {
    unelgeeRefPath: {
      type: String,
    },
  },
};
</script>
 